import localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';

// Initialize audit store
const auditStore = localforage.createInstance({
  name: 'auditLogs'
});

// Audit action types
export const AuditActionType = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_CREATED: 'USER_CREATED',
  USER_UPDATED: 'USER_UPDATED',
  USER_DELETED: 'USER_DELETED',
  BLOG_CREATED: 'BLOG_CREATED',
  BLOG_UPDATED: 'BLOG_UPDATED',
  BLOG_DELETED: 'BLOG_DELETED',
  COMMENT_MODERATED: 'COMMENT_MODERATED',
  SETTINGS_UPDATED: 'SETTINGS_UPDATED',
  SECURITY_EVENT: 'SECURITY_EVENT'
};

// Create audit log entry
export const createAuditLog = async (userId, actionType, details) => {
  const log = {
    id: uuidv4(),
    userId,
    actionType,
    details,
    timestamp: new Date(),
    userAgent: navigator.userAgent,
    ipAddress: await fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => data.ip)
      .catch(() => 'unknown')
  };

  await auditStore.setItem(log.id, log);
  return log;
};

// Get audit logs with filtering and pagination
export const getAuditLogs = async ({
  userId = null,
  actionType = null,
  startDate = null,
  endDate = null,
  page = 1,
  limit = 50
} = {}) => {
  const keys = await auditStore.keys();
  const logs = await Promise.all(
    keys.map(key => auditStore.getItem(key))
  );

  // Apply filters
  let filteredLogs = logs.filter(log => {
    if (userId && log.userId !== userId) return false;
    if (actionType && log.actionType !== actionType) return false;
    if (startDate && new Date(log.timestamp) < new Date(startDate)) return false;
    if (endDate && new Date(log.timestamp) > new Date(endDate)) return false;
    return true;
  });

  // Sort by timestamp descending
  filteredLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  // Apply pagination
  const start = (page - 1) * limit;
  const paginatedLogs = filteredLogs.slice(start, start + limit);

  return {
    logs: paginatedLogs,
    total: filteredLogs.length,
    page,
    totalPages: Math.ceil(filteredLogs.length / limit)
  };
};

// Get audit logs for a specific user
export const getUserAuditLogs = async (userId) => {
  return getAuditLogs({ userId });
};

// Get security events
export const getSecurityEvents = async () => {
  return getAuditLogs({ actionType: AuditActionType.SECURITY_EVENT });
};

// Clean up old audit logs (keep last 90 days)
export const cleanupAuditLogs = async () => {
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

  const keys = await auditStore.keys();
  const logs = await Promise.all(
    keys.map(key => auditStore.getItem(key))
  );

  const oldLogs = logs.filter(log => 
    new Date(log.timestamp) < ninetyDaysAgo
  );

  for (const log of oldLogs) {
    await auditStore.removeItem(log.id);
  }

  return oldLogs.length;
};

// Run cleanup periodically
setInterval(cleanupAuditLogs, 24 * 60 * 60 * 1000); // Once per day

export default auditStore; 