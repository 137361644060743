import localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import { createAuditLog, AuditActionType } from './auditStore';
import { getDefaultBlogPosts, getDefaultAIBlogPosts } from './seedManager';

// Initialize stores with consistent names
const stores = {
    blogPosts: localforage.createInstance({ name: 'blogPosts' }),
    blogVersions: localforage.createInstance({ name: 'blogVersions' }),
    blogComments: localforage.createInstance({ name: 'blogComments' }),
    blogSchedule: localforage.createInstance({ name: 'blogSchedule' })
};

// Blog post status enum
export const BlogStatus = {
    DRAFT: 'draft',
    SCHEDULED: 'scheduled',
    PUBLISHED: 'published',
    ARCHIVED: 'archived'
};

let isInitialized = false;

// Initialize the blog system
export const initializeBlogSystem = async () => {
    if (isInitialized) {
        return true;
    }

    try {
        // Check if we have any posts
        const keys = await stores.blogPosts.keys();
        
        if (keys.length === 0) {
            console.log("No blog posts found, initializing with defaults");
            
            // Get default posts
            const defaultPosts = getDefaultBlogPosts();
            const aiPosts = getDefaultAIBlogPosts();
            const allPosts = [...defaultPosts, ...aiPosts];
            
            // Store all posts
            await Promise.all(allPosts.map(async post => {
                const finalPost = {
                    ...post,
                    id: post.id || uuidv4(),
                    createdAt: post.createdAt || new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    status: post.status || BlogStatus.PUBLISHED
                };
                await stores.blogPosts.setItem(finalPost.id, finalPost);
            }));
            
            console.log(`Initialized ${allPosts.length} default blog posts`);
        }
        
        isInitialized = true;
        return true;
    } catch (error) {
        console.error("Error initializing blog system:", error);
        return false;
    }
};

// Create a new blog post
export const createBlogPost = async (postData) => {
    await initializeBlogSystem();

    const now = new Date();
    const post = {
        id: uuidv4(),
        ...postData,
        createdAt: now.toISOString(),
        updatedAt: now.toISOString(),
        status: postData.status || BlogStatus.DRAFT
    };

    // Create initial version
    const version = {
        id: uuidv4(),
        postId: post.id,
        title: post.title,
        content: post.content,
        seoMetadata: post.seoMetadata,
        createdAt: now.toISOString(),
        createdBy: post.author
    };

    await Promise.all([
        stores.blogPosts.setItem(post.id, post),
        stores.blogVersions.setItem(version.id, version)
    ]);

    await createAuditLog(post.author, AuditActionType.BLOG_CREATED, {
        postId: post.id,
        title: post.title
    });

    return post;
};

// Get all blog posts
export const getAllBlogs = async (status = 'all') => {
    await initializeBlogSystem();

    const keys = await stores.blogPosts.keys();
    const posts = await Promise.all(
        keys.map(key => stores.blogPosts.getItem(key))
    );

    const filteredPosts = posts.filter(post => 
        post && 
        (status === 'all' || post.status === status)
    );

    return filteredPosts.sort((a, b) => 
        new Date(b.publishedDate) - new Date(a.publishedDate)
    );
};

// Get a single blog post by ID
export const getBlogPost = async (id) => {
    await initializeBlogSystem();
    return await stores.blogPosts.getItem(id);
};

// Update a blog post
export const updateBlogPost = async (id, updates) => {
    await initializeBlogSystem();

    const existing = await stores.blogPosts.getItem(id);
    if (!existing) {
        throw new Error('Blog post not found');
    }

    const updated = {
        ...existing,
        ...updates,
        updatedAt: new Date().toISOString()
    };

    await stores.blogPosts.setItem(id, updated);

    // Create new version
    const version = {
        id: uuidv4(),
        postId: id,
        title: updated.title,
        content: updated.content,
        seoMetadata: updated.seoMetadata,
        createdAt: new Date().toISOString(),
        createdBy: updates.author || existing.author
    };

    await stores.blogVersions.setItem(version.id, version);

    return updated;
};

// Delete a blog post
export const deleteBlogPost = async (id) => {
    await initializeBlogSystem();
    
    const post = await stores.blogPosts.getItem(id);
    if (!post) {
        throw new Error('Blog post not found');
    }

    await stores.blogPosts.removeItem(id);
    return true;
};

// Force reset blog posts
export const forceResetBlogPosts = async () => {
    try {
        // Clear all blog-related stores
        await Promise.all([
            stores.blogPosts.clear(),
            stores.blogVersions.clear(),
            stores.blogComments.clear(),
            stores.blogSchedule.clear()
        ]);

        // Reset initialization flag
        isInitialized = false;

        // Reinitialize
        return await initializeBlogSystem();
    } catch (error) {
        console.error("Error resetting blog posts:", error);
        return false;
    }
};

// Export stores for direct access if needed
export const getBlogStores = () => stores;

// Get versions of a blog post
export const getBlogPostVersions = async (postId) => {
    await initializeBlogSystem();
    
    const keys = await stores.blogVersions.keys();
    const versions = await Promise.all(
        keys.map(key => stores.blogVersions.getItem(key))
    );
    
    return versions
        .filter(version => version.postId === postId)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

// Revert to a specific version
export const revertToVersion = async (postId, versionId) => {
    await initializeBlogSystem();
    
    const version = await stores.blogVersions.getItem(versionId);
    if (!version || version.postId !== postId) {
        throw new Error('Version not found or does not match post');
    }
    
    const post = await stores.blogPosts.getItem(postId);
    if (!post) {
        throw new Error('Blog post not found');
    }
    
    const updated = {
        ...post,
        title: version.title,
        content: version.content,
        seoMetadata: version.seoMetadata,
        updatedAt: new Date().toISOString()
    };
    
    await stores.blogPosts.setItem(postId, updated);
    return updated;
};

// Schedule a blog post
export const scheduleBlogPost = async (postId, publishDate) => {
    await initializeBlogSystem();
    
    const post = await stores.blogPosts.getItem(postId);
    if (!post) {
        throw new Error('Blog post not found');
    }
    
    const schedule = {
        id: uuidv4(),
        postId,
        publishDate,
        createdAt: new Date().toISOString()
    };
    
    await Promise.all([
        stores.blogSchedule.setItem(schedule.id, schedule),
        stores.blogPosts.setItem(postId, {
            ...post,
            status: BlogStatus.SCHEDULED,
            scheduledDate: publishDate,
            updatedAt: new Date().toISOString()
        })
    ]);
    
    return schedule;
};

// Get post comments
export const getPostComments = async (postId) => {
    await initializeBlogSystem();
    
    const keys = await stores.blogComments.keys();
    const comments = await Promise.all(
        keys.map(key => stores.blogComments.getItem(key))
    );
    
    return comments
        .filter(comment => comment.postId === postId)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

// Add a comment
export const addComment = async (postId, commentData) => {
    await initializeBlogSystem();
    
    const comment = {
        id: uuidv4(),
        postId,
        ...commentData,
        createdAt: new Date().toISOString(),
        status: 'pending'
    };
    
    await stores.blogComments.setItem(comment.id, comment);
    return comment;
};

// Moderate a comment
export const moderateComment = async (commentId, status) => {
    await initializeBlogSystem();
    
    const comment = await stores.blogComments.getItem(commentId);
    if (!comment) {
        throw new Error('Comment not found');
    }
    
    const updated = {
        ...comment,
        status,
        moderatedAt: new Date().toISOString()
    };
    
    await stores.blogComments.setItem(commentId, updated);
    return updated;
}; 