import { v4 as uuidv4 } from 'uuid';

/**
 * Services seed data with complete information for all services.
 * Centralized location for service data to avoid duplication.
 */
export const servicesSeedData = [
  {
    id: uuidv4(),
    title: 'Data Strategy',
    shortDescription: 'Transform complex data landscapes into strategic business assets.',
    description: 'Our Data Strategy service provides a comprehensive approach to leveraging your organization\'s most valuable asset: data. We help you develop a holistic data strategy that aligns with your business objectives, enabling data-driven decision-making and competitive advantage.',
    slug: 'data-strategy',
    icon: '⬡', 
    keyFeatures: [
      'Comprehensive Data Assessment',
      'Strategic Data Roadmap Development',
      'Data Monetization Strategies',
      'Advanced Analytics Framework'
    ],
    benefits: [
      'Improved Operational Efficiency',
      'Enhanced Decision-Making Capabilities',
      'Increased Revenue Potential',
      'Competitive Market Positioning'
    ],
    useCases: [
      'Enterprise-wide Data Transformation',
      'Cross-Departmental Data Integration',
      'Business Intelligence Optimization'
    ]
  },
  {
    id: uuidv4(),
    title: 'AI Advisory / Strategy',
    shortDescription: 'Expert guidance to navigate the AI revolution and develop transformative strategies.',
    description: 'Our AI Advisory service provides end-to-end strategic consulting to help organizations harness the power of artificial intelligence. We guide you through AI adoption, from initial assessment to implementation and scaling, ensuring your AI initiatives drive meaningful business value.',
    slug: 'ai-advisory',
    icon: '⬢', 
    keyFeatures: [
      'AI Maturity Assessment',
      'Custom AI Strategy Development',
      'Ethical AI Framework Design',
      'Technology and Talent Roadmap'
    ],
    benefits: [
      'Accelerated AI Adoption',
      'Reduced Implementation Risks',
      'Alignment of AI with Business Goals',
      'Competitive Technological Advantage'
    ],
    useCases: [
      'AI Strategy Formulation',
      'Innovation Ecosystem Design',
      'AI Governance and Compliance'
    ]
  },
  {
    id: uuidv4(),
    title: 'Generative AI',
    shortDescription: 'Unlock innovative content, designs, and solutions across your business.',
    description: 'Our Generative AI service empowers organizations to leverage cutting-edge generative technologies. We help you implement and optimize generative AI solutions that can transform content creation, product design, customer experience, and operational processes.',
    slug: 'generative-ai',
    icon: '◬', 
    keyFeatures: [
      'Custom Generative AI Models',
      'Content and Design Automation',
      'Creative Process Optimization',
      'Ethical AI Implementation'
    ],
    benefits: [
      'Accelerated Innovation',
      'Reduced Content Creation Costs',
      'Enhanced Creative Capabilities',
      'Personalized Customer Experiences'
    ],
    useCases: [
      'Marketing Content Generation',
      'Product Design Exploration',
      'Customer Interaction Personalization'
    ]
  },
  {
    id: uuidv4(),
    title: 'Data Governance',
    shortDescription: 'Establish robust frameworks for data quality, compliance, and ethical use.',
    description: 'Our Data Governance service provides a comprehensive approach to managing your organization\'s data assets. We help you develop robust governance frameworks that ensure data quality, security, compliance, and ethical use across your entire data ecosystem.',
    slug: 'data-governance',
    icon: '⬟', 
    keyFeatures: [
      'Data Governance Framework Design',
      'Data Quality Management',
      'Regulatory Compliance Solutions',
      'Data Ethics Guidelines'
    ],
    benefits: [
      'Enhanced Data Security',
      'Improved Regulatory Compliance',
      'Increased Data Trust and Quality',
      'Reduced Data Management Risks'
    ],
    useCases: [
      'Regulatory Compliance Programs',
      'Data Quality Initiatives',
      'Enterprise Data Protection'
    ]
  },
  {
    id: uuidv4(),
    title: 'Digital Transformation',
    shortDescription: 'Revolutionize your business models and operations through digital innovation.',
    description: 'Our Digital Transformation service helps organizations evolve their business models and operations through strategic technology adoption. We guide you through the complex journey of digital transformation, ensuring alignment with business objectives and maximizing ROI.',
    slug: 'digital-transformation',
    icon: '⬣', 
    keyFeatures: [
      'Digital Maturity Assessment',
      'Transformation Roadmap Development',
      'Technology Stack Modernization',
      'Change Management Support'
    ],
    benefits: [
      'Enhanced Customer Experience',
      'Improved Operational Efficiency',
      'New Revenue Streams',
      'Increased Market Competitiveness'
    ],
    useCases: [
      'Enterprise-wide Digital Transformation',
      'Legacy System Modernization',
      'Customer Experience Enhancement'
    ]
  },
  {
    id: uuidv4(),
    title: 'Enterprise Data Management',
    shortDescription: 'Implement holistic solutions for data integration, quality, and accessibility.',
    description: 'Our Enterprise Data Management service provides comprehensive solutions for managing your organization\'s data assets across the entire lifecycle. We help you establish robust frameworks for data integration, quality management, and accessibility that drive business value.',
    slug: 'enterprise-data-management',
    icon: '⯃', 
    keyFeatures: [
      'Master Data Management',
      'Data Integration Architecture',
      'Metadata Management',
      'Data Quality Framework'
    ],
    benefits: [
      'Unified View of Enterprise Data',
      'Improved Decision Making',
      'Enhanced Operational Efficiency',
      'Reduced Data Management Costs'
    ],
    useCases: [
      'Master Data Management Implementation',
      'Data Quality Initiative',
      'Data Architecture Modernization'
    ],
    technologiesUsed: [
      'Informatica',
      'Talend',
      'Collibra',
      'AWS Glue',
      'Azure Data Factory',
      'Snowflake'
    ]
  }
]; 