import localforage from 'localforage';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { seedData, shouldUpdateSeedData, updateSeedVersion } from './seedManager';
import { hashPassword, verifyPassword } from '../utils/browserSecurity';

// Initialize stores with consistent names
const stores = {
    users: localforage.createInstance({ name: 'users' }),
    blogPosts: localforage.createInstance({ name: 'blogPosts' }),
    services: localforage.createInstance({ name: 'services' }),
    contactSubmissions: localforage.createInstance({ name: 'contactSubmissions' }),
    userInteractions: localforage.createInstance({ name: 'userInteractions' }),
    blogVersions: localforage.createInstance({ name: 'blogVersions' }),
    blogComments: localforage.createInstance({ name: 'blogComments' }),
    blogSchedule: localforage.createInstance({ name: 'blogSchedule' })
};

let databaseInitialized = false;

// Clear a single store
async function clearStore(store) {
    const keys = await store.keys();
    await Promise.all(keys.map(key => store.removeItem(key)));
}

// Clear all stores
async function clearAllStores() {
    await Promise.all(Object.values(stores).map(clearStore));
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('user');
    console.log('All stores cleared');
}

// Reset the entire database
export async function resetDatabase() {
    console.log('Starting database reset...');
    
    try {
        // Clear all stores
        await clearAllStores();
        
        // Reinitialize database with fresh seed data
        await initializeDatabase(true);
        
        console.log('Database reset complete! Default admin credentials:');
        console.log('Username: admin');
        console.log('Password: admin123');
        
        return true;
    } catch (error) {
        console.error('Error resetting database:', error);
        return false;
    }
}

async function ensureAdminUser() {
  try {
    const existingUsers = await stores.users.keys();
    const needsUpdate = await shouldUpdateSeedData();
    
    if (existingUsers.length === 0 || needsUpdate) {
      const adminUser = await seedData.getDefaultAdminUser();
      if (!adminUser.id || !adminUser.username || !adminUser.password) {
        throw new Error('Invalid admin user data structure');
      }
      await stores.users.setItem(adminUser.id, adminUser);
      return true;
    }

    const users = await Promise.all(existingUsers.map(id => stores.users.getItem(id)));
    const adminUsers = users.filter(user => user.role === 'admin');
    
    if (adminUsers.length === 0) {
      const adminUser = await seedData.getDefaultAdminUser();
      await stores.users.setItem(adminUser.id, adminUser);
    }
    return true;
  } catch (error) {
    console.error('Error ensuring admin user:', error);
    throw error;
  }
}

async function initializeStore(storeName, getDefaultItems) {
  try {
    const store = stores[storeName];
    const existingKeys = await store.keys();
    const needsUpdate = await shouldUpdateSeedData();

    if (existingKeys.length === 0 || needsUpdate) {
      if (existingKeys.length > 0) {
        await clearStore(store);
      }
      const defaultItems = getDefaultItems();
      await Promise.all(defaultItems.map(item => store.setItem(item.id, item)));
    }
    return true;
  } catch (error) {
    console.error(`Error initializing ${storeName}:`, error);
    throw error;
  }
}

export async function initializeDatabase(freshSeed = false) {
  if (databaseInitialized) {
    return true;
  }

  try {
    const needsUpdate = await shouldUpdateSeedData();
    
    // Initialize all stores
    await ensureAdminUser();
    await initializeStore('services', seedData.getDefaultServices);
    await initializeStore('blogPosts', seedData.getDefaultBlogPosts);
    
    if (needsUpdate) {
      await updateSeedVersion();
    }

    databaseInitialized = true;
    return true;
  } catch (error) {
    console.error('Database initialization failed:', error);
    throw error;
  }
}

// User Management
export async function registerUser(username, email, password) {
  try {
    // Input validation
    if (!username || !email || !password) {
      throw new Error('All fields are required');
    }

    // Check if username or email already exists
    const existingUsers = await stores.users.keys();
    const users = await Promise.all(
      existingUsers.map(async key => await stores.users.getItem(key))
    );

    const userExists = users.some(
      user => user.username === username || user.email === email
    );

    if (userExists) {
      throw new Error('Username or email already exists');
    }

    // Hash password
    const hashedPassword = hashPassword(password);

    // Create new user with hashed password
    const newUser = {
      id: uuidv4(),
      username,
      email,
      password: hashedPassword,
      role: 'user',
      createdAt: new Date().toISOString(),
      lastLogin: null
    };

    // Store user
    await stores.users.setItem(newUser.id, newUser);
    return newUser;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
}

export async function authenticateUser(username, password) {
  try {
    // Input validation
    if (!username || !password) {
      console.error('Authentication failed: username or password is missing');
      throw new Error('Username and password are required');
    }

    console.log('Authentication attempt:', { username });

    // Get all users and find matching username
    const userIds = await stores.users.keys();
    console.log('Existing user IDs for authentication:', userIds.length);

    if (userIds.length === 0) {
      console.error('No users found in database during authentication');
      // Auto-initialize database if no users found
      await initializeDatabase();
      return null;
    }

    const users = await Promise.all(
      userIds.map(async id => await stores.users.getItem(id))
    );

    console.log('User credentials for authentication:', users.map(u => ({ 
      username: u.username, 
      role: u.role,
      hasPassword: !!u.password 
    })));

    const user = users.find(u => u.username === username);
    
    if (!user) {
      console.warn(`No user found with username: ${username}`);
      return null;
    }

    console.log('User found for authentication:', { 
      id: user.id, 
      username: user.username, 
      role: user.role,
      hasPassword: !!user.password
    });

    // Verify password
    if (!user.password) {
      console.error('User has no password field:', user);
      return null;
    }

    // Add detailed logging for password verification
    console.log('Password verification details:', {
      providedPasswordLength: password.length,
      storedPasswordFormat: user.password.substring(0, 7) + '...'
    });

    // Use browserSecurity verifyPassword to compare passwords
    try {
      const isValidPassword = await verifyPassword(password, user.password);
      console.log('Password validation result:', isValidPassword);

      if (!isValidPassword) {
        console.warn('Password validation failed for user:', username);
        return null;
      }
    } catch (passwordError) {
      console.error('Password comparison error:', passwordError);
      return null;
    }

    // Update last login
    const updatedUser = {
      ...user,
      lastLogin: new Date().toISOString()
    };
    await stores.users.setItem(user.id, updatedUser);
    console.log('Updated user last login timestamp');

    // Return user without sensitive information
    const { password: _, ...safeUserData } = updatedUser;
    
    // Add twoFactorEnabled property if it doesn't exist
    if (safeUserData.twoFactorEnabled === undefined) {
      safeUserData.twoFactorEnabled = false;
    }
    
    console.log('Authentication successful, returning user data:', safeUserData);
    return safeUserData;
  } catch (error) {
    console.error('Error authenticating user:', error);
    throw error;
  }
}

// Blog Post Management
export async function createBlogPost(postData) {
  try {
    // Validate required fields
    if (!postData.title || !postData.content || !postData.author) {
      throw new Error('Title, content, and author are required');
    }

    // Generate unique ID if not provided
    const finalPostData = {
      ...postData,
      id: postData.id || `post_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      createdAt: postData.createdAt || new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: postData.status || 'draft'
    };

    // Validate tags
    if (finalPostData.tags && !Array.isArray(finalPostData.tags)) {
      finalPostData.tags = finalPostData.tags.split(',').map(tag => tag.trim());
    }

    console.log('Storing blog post:', finalPostData);

    // Store the post
    await stores.blogPosts.setItem(finalPostData.id, finalPostData);
    
    // Verify post was stored
    const storedPost = await stores.blogPosts.getItem(finalPostData.id);
    console.log('Stored blog post:', storedPost);

    return finalPostData;
  } catch (error) {
    console.error('Error creating blog post:', error);
    throw error;
  }
}

export async function updateBlogPost(postId, updates) {
  try {
    // Retrieve existing post
    const existingPost = await stores.blogPosts.getItem(postId);
    
    if (!existingPost) {
      throw new Error('Blog post not found');
    }

    // Merge updates
    const updatedPost = {
      ...existingPost,
      ...updates,
      updatedAt: new Date().toISOString()
    };

    // Validate required fields
    if (!updatedPost.title || !updatedPost.content || !updatedPost.author) {
      throw new Error('Title, content, and author are required');
    }

    // Store updated post
    await stores.blogPosts.setItem(postId, updatedPost);
    return updatedPost;
  } catch (error) {
    console.error('Error updating blog post:', error);
    throw error;
  }
}

export async function deleteBlogPost(postId) {
  try {
    // Check if post exists before deleting
    const existingPost = await stores.blogPosts.getItem(postId);
    
    if (!existingPost) {
      throw new Error('Blog post not found');
    }

    await stores.blogPosts.removeItem(postId);
    return { success: true, message: 'Blog post deleted successfully' };
  } catch (error) {
    console.error('Error deleting blog post:', error);
    throw error;
  }
}

export async function getBlogPosts(status = 'published') {
  try {
    console.log(`Getting blog posts with status: ${status}`);
    
    // Get all keys from the store
    const keys = await stores.blogPosts.keys();
    console.log(`Found ${keys.length} blog post keys`);
    
    if (keys.length === 0) {
      console.log('No blog posts found in the store, trying to initialize');
      // Ensure initialization is complete
      await initializeDatabase();
      const newKeys = await stores.blogPosts.keys();
      if (newKeys.length === 0) {
        console.warn('Still no blog posts after initialization');
        return [];
      }
    }
    
    // Get all blog posts
    const allPosts = await Promise.all(
      keys.map(async key => {
        try {
          const post = await stores.blogPosts.getItem(key);
          return post;
        } catch (error) {
          console.error(`Error retrieving blog post with key ${key}:`, error);
          return null;
        }
      })
    );
    
    // Filter out null values and by status if requested
    const validPosts = allPosts
      .filter(post => post !== null)
      .filter(post => {
        if (status === 'all' || !status) {
          return true;
        }
        return post.status === status;
      });
    
    console.log(`Returning ${validPosts.length} blog posts after filtering`);
    
    return validPosts;
  } catch (error) {
    console.error('Error getting blog posts:', error);
    return [];
  }
}

// Service Management
export async function createService(serviceData) {
  const newService = {
    ...serviceData,
    id: uuidv4(),
    createdAt: new Date().toISOString(),
  };

  await stores.services.setItem(newService.id, newService);
  return newService;
}

export async function getServices(category = null) {
  const keys = await stores.services.keys();
  const services = await Promise.all(
    keys.map(key => stores.services.getItem(key))
  );

  return category
    ? services.filter(service => service.category === category)
    : services;
}

export async function updateService(serviceId, updates) {
  const service = await stores.services.getItem(serviceId);
  if (!service) {
    throw new Error('Service not found');
  }

  const updatedService = {
    ...service,
    ...updates,
    updatedAt: new Date().toISOString(),
  };

  await stores.services.setItem(serviceId, updatedService);
  return updatedService;
}

// Contact Submission Management
export async function submitContactForm(contactData) {
  // Extract recaptchaToken from the data
  const { recaptchaToken, ...formData } = contactData;
  
  // In a real production environment, you would verify the token with Google's API
  // This would typically be done on the server side
  // Example implementation is provided in README-RECAPTCHA.md
  
  // For now, we'll just check if the token exists
  if (!recaptchaToken) {
    throw new Error('CAPTCHA verification failed. Please try again.');
  }
  
  // In production, implement server-side verification:
  // const isValidRecaptcha = await verifyRecaptchaWithGoogle(recaptchaToken);
  // if (!isValidRecaptcha) {
  //   throw new Error('CAPTCHA verification failed. Please try again.');
  // }
  
  const newSubmission = {
    ...formData,
    id: uuidv4(),
    status: 'pending',
    createdAt: new Date().toISOString(),
    captchaVerified: true, // In production, this would be the result of actual verification
  };

  await stores.contactSubmissions.setItem(newSubmission.id, newSubmission);
  
  // In a real app, you might want to send an email notification here
  // Example: await sendEmailNotification(newSubmission);
  
  return newSubmission;
}

export async function getContactSubmissions(status = null) {
  const keys = await stores.contactSubmissions.keys();
  const submissions = await Promise.all(
    keys.map(key => stores.contactSubmissions.getItem(key))
  );

  return status
    ? submissions.filter(submission => submission.status === status)
    : submissions;
}

// User Interaction Tracking
export async function trackUserInteraction(interactionData) {
  const newInteraction = {
    ...interactionData,
    id: uuidv4(),
    timestamp: new Date().toISOString(),
  };

  await stores.userInteractions.setItem(newInteraction.id, newInteraction);
  return newInteraction;
}

// Add a new function to verify blog posts are in the database
async function verifyBlogPosts() {
  try {
    const keys = await stores.blogPosts.keys();
    console.log('Verifying blog posts - found keys:', keys);
    
    if (keys.length === 0) {
      console.warn('No blog post keys found during verification');
      return false;
    }
    
    // Sample a few posts to verify content
    const sampleSize = Math.min(3, keys.length);
    const sampleKeys = keys.slice(0, sampleSize);
    
    const samples = await Promise.all(
      sampleKeys.map(async key => {
        const post = await stores.blogPosts.getItem(key);
        return {
          id: post.id,
          title: post.title,
          hasContent: !!post.content,
          contentLength: post.content ? post.content.length : 0
        };
      })
    );
    
    console.log('Blog post samples:', samples);
    
    return keys.length > 0;
  } catch (error) {
    console.error('Error verifying blog posts:', error);
    return false;
  }
}

export {
  stores
};
