import localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import { servicesSeedData } from './seedData/services';
import { blogPostsSeedData } from './seedData/blogPosts';
import { aiBlogPostsSeedData } from './seedData/aiBlogs';
import { hashPassword } from '../utils/browserSecurity';

// Seed data version - increment when making significant changes to seed data
export const SEED_DATA_VERSION = '1.6.0';

// Store for seed data version tracking
const seedVersionStore = localforage.createInstance({
  name: 'seedVersion'
});

/**
 * Check if seed data needs to be updated based on version
 * @returns {Promise<boolean>} True if seed data needs updating
 */
export async function shouldUpdateSeedData() {
  try {
    const currentVersion = await seedVersionStore.getItem('version');
    console.log('Current seed version:', currentVersion, 'Target version:', SEED_DATA_VERSION);
    
    // If no version exists or version is different, we need to update
    return !currentVersion || currentVersion !== SEED_DATA_VERSION;
  } catch (error) {
    console.error('Error checking seed version:', error);
    return true; // Default to updating if there's an error
  }
}

/**
 * Update the stored seed version
 * @returns {Promise<void>}
 */
export async function updateSeedVersion() {
  try {
    await seedVersionStore.setItem('version', SEED_DATA_VERSION);
    console.log('Seed version updated to:', SEED_DATA_VERSION);
  } catch (error) {
    console.error('Error updating seed version:', error);
  }
}

/**
 * Get default services seed data
 * @returns {Array} Array of service objects
 */
export function getDefaultServices() {
  return servicesSeedData;
}

/**
 * Get default admin user seed data
 * @returns {Array} Admin user object
 */
export async function getDefaultAdminUser() {
  // Create admin user with consistent field naming (password, not passwordHash)
  // This ensures compatibility with the authenticateUser function
  const hashedPassword = await hashPassword('admin123');
  
  return {
    id: uuidv4(),
    username: 'admin',
    // Store as password, not passwordHash, for consistency with authenticateUser
    password: hashedPassword,
    email: 'admin@sharedoxygen.com',
    role: 'admin',
    createdAt: new Date().toISOString(),
    twoFactorEnabled: false
  };
}

/**
 * Get default blog posts seed data
 * @returns {Array} Array of blog post objects
 */
export function getDefaultBlogPosts() {
  console.log('Getting default blog posts, count:', blogPostsSeedData.length);
  
  // Ensure all posts have IDs and Admin as author
  return blogPostsSeedData.map(post => ({
    ...post,
    id: post.id || `post_${uuidv4()}`,
    author: 'Shared Oxygen',
    createdAt: post.createdAt || new Date().toISOString(),
    status: post.status || 'published'
  }));
}

/**
 * Get default AI blog posts seed data
 * @returns {Array} Array of AI blog post objects
 */
export function getDefaultAIBlogPosts() {
  console.log('Getting default AI blog posts, count:', aiBlogPostsSeedData.length);
  
  // Ensure all posts have IDs and Admin as author
  return aiBlogPostsSeedData.map(post => ({
    ...post,
    id: post.id || `post_${uuidv4()}`,
    author: 'Shared Oxygen',
    createdAt: post.createdAt || new Date().toISOString(),
    status: post.status || 'published'
  }));
}

// Export all seed data functions for use in initDB.js
export const seedData = {
  getDefaultServices,
  getDefaultAdminUser,
  getDefaultBlogPosts,
  getDefaultAIBlogPosts,
  shouldUpdateSeedData,
  updateSeedVersion
}; 