// Use browser-compatible crypto libraries instead of Node.js modules
import CryptoJS from 'crypto-js';

// Constants
const JWT_SECRET = 'shared-oxygen-jwt-secret-key';
const JWT_EXPIRY = '7d'; // 7 days

// Password hashing
export const hashPassword = async (password) => {
  return CryptoJS.SHA256(password).toString(CryptoJS.enc.Base64);
};

export const verifyPassword = async (password, hashedPassword) => {
  const hashed = await hashPassword(password);
  return hashed === hashedPassword;
};

// JWT functions using browser-compatible approach
export const generateToken = (payload) => {
  // Simple JWT implementation for browser
  const header = {
    alg: 'HS256',
    typ: 'JWT'
  };
  
  const now = Math.floor(Date.now() / 1000);
  const expirySeconds = 60 * 60 * 24 * 7; // 7 days in seconds
  
  const payloadWithClaims = {
    ...payload,
    iat: now,
    exp: now + expirySeconds
  };
  
  const base64Header = btoa(JSON.stringify(header));
  const base64Payload = btoa(JSON.stringify(payloadWithClaims));
  
  const dataToSign = `${base64Header}.${base64Payload}`;
  const signature = CryptoJS.HmacSHA256(dataToSign, JWT_SECRET).toString(CryptoJS.enc.Base64);
  
  return `${base64Header}.${base64Payload}.${signature}`;
};

export const verifyToken = (token) => {
  try {
    const [base64Header, base64Payload, signature] = token.split('.');
    
    // Verify signature
    const dataToSign = `${base64Header}.${base64Payload}`;
    const expectedSignature = CryptoJS.HmacSHA256(dataToSign, JWT_SECRET).toString(CryptoJS.enc.Base64);
    
    if (signature !== expectedSignature) {
      return null;
    }
    
    // Decode payload
    const payload = JSON.parse(atob(base64Payload));
    
    // Check expiration
    const now = Math.floor(Date.now() / 1000);
    if (payload.exp && payload.exp < now) {
      return null;
    }
    
    return payload;
  } catch (error) {
    console.error('Token verification error:', error);
    return null;
  }
};

// 2FA functions (simplified for browser compatibility)
export const generate2FASecret = () => {
  // Generate a random string as secret
  const randomBytes = CryptoJS.lib.WordArray.random(20);
  return CryptoJS.enc.Base64.stringify(randomBytes).replace(/=/g, '');
};

export const generate2FAToken = (secret) => {
  // Simplified implementation for browser
  const randomDigits = Math.floor(Math.random() * 900000) + 100000;
  return randomDigits.toString();
};

export const verify2FAToken = (token, secret) => {
  // For simplicity, we'll accept any token for now
  // In a real implementation, we would validate the token against the secret
  return token.length === 6 && /^\d+$/.test(token);
};

// Simple rate limiting
export const loginRateLimiter = (maxAttempts = 5, windowMs = 60 * 60 * 1000) => {
  // Simple in-memory rate limiting for demonstration
  const attempts = new Map();
  
  return (username) => {
    const now = Date.now();
    const key = username || 'anonymous';
    
    if (!attempts.has(key)) {
      attempts.set(key, { count: 1, resetAt: now + windowMs });
      return true;
    }
    
    const userAttempts = attempts.get(key);
    
    if (now > userAttempts.resetAt) {
      attempts.set(key, { count: 1, resetAt: now + windowMs });
      return true;
    }
    
    if (userAttempts.count >= maxAttempts) {
      return false;
    }
    
    userAttempts.count++;
    attempts.set(key, userAttempts);
    return true;
  };
};

// Password validation
export const validatePassword = (password) => {
  // At least 8 characters, including uppercase, lowercase, number and special char
  const minLength = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
  
  return {
    isValid: minLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar,
    errors: [
      !minLength && 'Password must be at least 8 characters long',
      !hasUppercase && 'Password must contain at least one uppercase letter',
      !hasLowercase && 'Password must contain at least one lowercase letter',
      !hasNumber && 'Password must contain at least one number',
      !hasSpecialChar && 'Password must contain at least one special character'
    ].filter(Boolean)
  };
};

// Session management
export const createSession = (user) => {
  if (!user || !user.id) {
    throw new Error('User is required to create a session');
  }
  
  // Only create sessions for admin users
  if (user.role !== 'admin') {
    throw new Error('Only administrators can create sessions');
  }
  
  // Generate a token with user information
  const token = generateToken({
    userId: user.id,
    role: user.role,
    username: user.username
  });
  
  return {
    token,
    user: {
      id: user.id,
      username: user.username,
      role: user.role
    }
  };
};

export const validateSession = (token) => {
  if (!token) {
    return { valid: false, error: 'No session token provided' };
  }
  
  try {
    const payload = verifyToken(token);
    
    if (!payload) {
      return { valid: false, error: 'Invalid or expired session' };
    }
    
    return {
      valid: true,
      user: {
        id: payload.userId,
        username: payload.username,
        role: payload.role
      }
    };
  } catch (error) {
    return { valid: false, error: 'Session validation failed' };
  }
}; 